@import url('https://fonts.googleapis.com/css?family=Raleway:400,700&display=swap');

body {
	font-family: 'Raleway', sans-serif;
	/* text-align: center; */
}

.title-head {
	text-align: center;
	color: #707070;
}

.title-head h1 {
	font-weight: 700;
	font-size: 2.7rem;
	margin-bottom: 0;
	margin-top: 60px;
}

.title-head h3 {
	font-weight: 400;
	margin-top: 10px;
}

.meme-container {
	border: 1px solid white;
	width: 300px;
	font-family: Arial, Helvetica, sans-serif;
	-webkit-appearance: none;
	-webkit-box-shadow: 11px 11px 40px -11px rgba(0, 0, 0, 0.68);
	-moz-box-shadow: 11px 11px 40px -11px rgba(0, 0, 0, 0.68);
	box-shadow: 11px 11px 40px -11px rgba(0, 0, 0, 0.68);
	text-align: left;
	color: #000;
	margin: 0 40px;
	animation-name: faderightani;
	animation-duration: 1s;
}

.meme-container p {
	padding-left: 10px;
	padding-right: 10px;
	font-size: 20px;
	margin-bottom: 5px;
}

.meme-container img {
	border-radius: 20px;
	padding: 10px;
	width: 280px;
}

.settings-container {
	width: 300px;
	margin-top: 30px;
	margin-left: 40px;
	margin-right: 40px;
	animation-name: fadeleftani;
	animation-duration: 1s;
}

.main-container {
	margin-left: auto;
	margin-right: auto;
	margin-top: 50px;
	width: fit-content;
	display: flex;
	justify-content: center;
}

.line-divider {
	border-left: .7px solid #707070;
}

.settings-container h6 {
	font-size: 1em;
	text-decoration: underline;
	margin-top: 10px;
}

.meme-caption-text {
	width: 300px;
	height: 50px;
	resize: none;
	-webkit-appearance: none;
	-webkit-box-shadow: 11px 11px 40px -11px rgba(0, 0, 0, 0.30);
	-moz-box-shadow: 11px 11px 40px -11px rgba(0, 0, 0, 0.30);
	box-shadow: 11px 11px 40px -11px rgba(0, 0, 0, 0.30);
	border: none;
	outline: none;
	padding: 10px;
}

/* .meme-container img:hover {
	opacity: 50%;
} */

.settings-container button {
	margin-top: 20px;
}

button {
	color: #fff;
	font-weight: bold;
	padding: 10px 20px;
	border: none;
}

.blue-btn {
	-webkit-appearance: none;
	-webkit-box-shadow: 11px 11px 40px -11px rgb(45, 171, 255);
	-moz-box-shadow: 11px 11px 40px -11px rgb(45, 171, 255);
	box-shadow: 11px 11px 40px -11px rgb(45, 171, 255);
	background-color: #2186f1;
}

.green-btn {
	-webkit-appearance: none;
	-webkit-box-shadow: 11px 11px 40px -11px rgb(45, 255, 45);
	-moz-box-shadow: 11px 11px 40px -11px rgb(45, 255, 115);
	box-shadow: 11px 11px 40px -11px rgb(45, 255, 73);
	background-color: #1cbb29;
}

.green-btn:hover {
	background-color: #2cce3a;
}

.red-btn {
	-webkit-appearance: none;
	-webkit-box-shadow: 11px 11px 40px -11px rgb(255, 45, 45);
	-moz-box-shadow: 11px 11px 40px -11px rgb(255, 45, 45);
	box-shadow: 11px 11px 40px -11px rgb(255, 45, 45);
	background-color: #ff0000;
}

.blue-btn:hover {
	background-color: #3f97f5;
}

.red-btn:hover {
	background-color: #f53f3f;
}

button:hover {
	cursor: pointer;
}

.settings-container input {
	border: none;
	-webkit-appearance: none;
	-webkit-box-shadow: 11px 11px 40px -11px rgba(0, 0, 0, 0.30);
	-moz-box-shadow: 11px 11px 40px -11px rgba(0, 0, 0, 0.30);
	box-shadow: 11px 11px 40px -11px rgba(0, 0, 0, 0.30);
	outline: none;
	width: 280px;
	margin-top: 20px;
	padding: 10px;
	font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.settings-container textarea {
	width: 280px;
}

.img-container {
	position: relative;
	display: inline-block;
}

.img-container p {
	position: absolute;
	z-index: 999;
	margin: 0 auto;
	left: 20%;
	right: 0;
	text-align: right;
	top: 85%; /* Adjust this value to move the positioned div up and down */
	font-family: Arial, sans-serif;
	color: rgba(121, 121, 121, 0.575);
	font-weight: bold;
	width: 60%; /* Set the width of the positioned div */
	font-size: 10px;
}

.home-upload-box {
	-webkit-appearance: none;
	-webkit-box-shadow: 11px 11px 40px -11px rgba(0, 0, 0, 0.30);
	-moz-box-shadow: 11px 11px 40px -11px rgba(0, 0, 0, 0.30);
	box-shadow: 11px 11px 40px -11px rgba(0, 0, 0, 0.30);
	margin-left: auto;
	margin-right: auto;
	width: 30%;
	margin-top: 40px;
	padding-top: 20px;
	padding-bottom: 40px;
	animation-name: fadeupani;
	animation-duration: 1s;
}

@keyframes fadeupani {
	from {
		opacity: 0;
		transform: translateY(20px);
	}
	to {
		opacity: 1;
		transform: translateY(0px);
	}
}

@keyframes fadeoutup {
	from {
		opacity: 1;
		transform: translateY(0px);
	}
	to {
		opacity: 0;
		transform: translateY(20px);
	}
}

@keyframes fadeleftani {
	from {
		opacity: 0;
		transform: translateX(50px);
	}
	to {
		opacity: 1;
		transform: translateY(0px);
	}
}

@keyframes faderightani {
	from {
		opacity: 0;
		transform: translateX(-50px);
	}
	to {
		opacity: 1;
		transform: translateY(0px);
	}
}

@keyframes shake {
	10%,
	90% {
		transform: translate3d(-1px, 0, 0);
	}

	20%,
	80% {
		transform: translate3d(2px, 0, 0);
	}

	30%,
	50%,
	70% {
		transform: translate3d(-4px, 0, 0);
	}

	40%,
	60% {
		transform: translate3d(4px, 0, 0);
	}
}

.home-popular-box {
	-webkit-appearance: none;
	/* -webkit-box-shadow: 11px 11px 40px -11px rgba(0, 0, 0, 0.30);
	-moz-box-shadow: 11px 11px 40px -11px rgba(0, 0, 0, 0.30);
	box-shadow: 11px 11px 40px -11px rgba(0, 0, 0, 0.30); */
	margin-left: auto;
	margin-right: auto;
	width: 70%;
	margin-top: 30px;
	padding-top: 20px;
	padding-bottom: 40px;
	margin-bottom: 100px;
	animation-name: fadeupani;
	animation-duration: 1s;
}

html {
	overflow-y: scroll;
}

.home-popular-box h3 {
	font-weight: bold;
	text-decoration: underline;
}

.meme-container {
	background-color: #fff;
}

@media only screen and (max-width: 1300px) and (min-width: 1100px) {
	.home-upload-box {
		width: 40%;
	}
}

@media only screen and (max-width: 1100px) and (min-width: 900px) {
	.home-upload-box {
		width: 50%;
	}
}

@media only screen and (max-width: 900px) and (min-width: 700px) {
	.home-upload-box {
		width: 60%;
	}
}

@media only screen and (max-width: 700px) and (min-width: 400px) {
	.home-upload-box {
		width: 80%;
	}
}

@media only screen and (max-width: 400px) {
	.home-upload-box {
		width: 90%;
	}
}

button:focus {
	outline: none;
}

@media only screen and (max-width: 760px) {
	.main-container {
		flex-direction: column;
		margin-bottom: 100px;
	}
	.line-divider {
		display: none;
	}
}

.blue-btn:disabled {
	background-color: #5aaaff;
}

.templates-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.template-container {
	/* border: .5px solid #707070; */
	-webkit-appearance: none;
	-webkit-box-shadow: 11px 11px 40px -11px rgba(0, 0, 0, 0.30);
	-moz-box-shadow: 11px 11px 40px -11px rgba(0, 0, 0, 0.30);
	box-shadow: 11px 11px 40px -11px rgba(0, 0, 0, 0.30);
	width: 300px;
	margin: 20px;
	padding-bottom: 30px;
}

/* .template-container:hover {
	background-color: #ececec;
	cursor: pointer;
} */

.template-container img {
	width: 300px;
	height: 160px;
	margin: 0;
	object-fit: cover;
}

@media only screen and (max-width: 1470px) and (min-width: 1350px) {
	.home-popular-box {
		width: 80%;
	}
}

@media only screen and (max-width: 1350px) and (min-width: 0px) {
	.home-popular-box {
		width: 90%;
	}
}

.home-popular-box p {
	margin-left: 10px;
	margin-right: 10px;
}

.home-popular-box input {
	border: none;
	-webkit-appearance: none;
	-webkit-box-shadow: 11px 11px 40px -11px rgba(0, 0, 0, 0.30);
	-moz-box-shadow: 11px 11px 40px -11px rgba(0, 0, 0, 0.30);
	box-shadow: 11px 11px 40px -11px rgba(0, 0, 0, 0.30);
	outline: none;
	width: 280px;
	margin-top: 20px;
	padding: 10px;
	font-family: Verdana, Geneva, Tahoma, sans-serif;
	margin-bottom: 25px;
}

@media only screen and (max-width: 450px) {
	.home-popular-box input {
		width: 200px;
	}
}
